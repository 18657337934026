import { readable, writable } from "svelte/store";
import { fetchTranslation } from "@/common/i18n.js";

const FLASH_MESSAGES = ['error', 'success', 'warn']
const VALIDATION_ERRORS = ['errors']

function getFlash() {
  return globalThis.vesta?.flash || []
}

export const formValues = readable({}, (set) => {
  set(getFlash()
    .filter(group => ![].concat(FLASH_MESSAGES, VALIDATION_ERRORS).includes(group[0]))
    .reduce((values, [field, value]) => ({...values, [field]: value}), {}))
})

export const formValidationErrors = writable({}, (set) => {
  const relevantMessages = getFlash()
    .filter(group => VALIDATION_ERRORS.includes(group[0]))
    .map(group => group[1])
  if(relevantMessages.length > 0) {
    set(relevantMessages[0])
  }
})

export const messages = readable([], (set) => {
  set(getFlash()
    .filter(group => FLASH_MESSAGES.includes(group[0]))
    .map(group => ({
      type: group[0],
      message: fetchTranslation(group[1]['key'] || group[1]),
      data: group[1],
    })))
})